import React from 'react';

const List = ({ shopLabelSummaries }) => {
  const achievementRate = (actual, goal, minusGoal = false) => {
    if (!goal || !actual) {
      return 0;
    }

    let rate = actual / goal;
    if (minusGoal && goal < 0) {
      rate = 1 - ((goal - actual) / (-1 * goal))
    }

    return round(rate * 100);
  };

  const round = (value) => {
    return Math.round(value * 10) / 10.0;
  };

  const renderSummaries = () => {
    const summaries = shopLabelSummaries.map((shopLabelSummary, index) => {
      const shopLabel = shopLabelSummary[0];
      const data = shopLabelSummary[1];

      const progressRateSales = achievementRate(data.excluded_sales, data.total_up_daily_budgets, true);

      let colorClassFoodCostRate = '';
      if (data.food_cost_budget !== 0) {
        colorClassFoodCostRate = data.food_cost_rate > data.food_cost_budget ? 'text-danger' : 'text-primary';
      }
      let colorClassLaborCostRate = '';
      if (data.labor_cost_budget !== 0) {
        colorClassLaborCostRate = data.labor_cost_rate > data.labor_cost_budget ? 'text-danger' : 'text-primary';
      }

      return (
        <tr className="align-middle" key={`shop-label-summary-${shopLabel.id}`}>
          <td className="fw-bolder fs-5">{index + 1}</td>
          <td className="fw-bold fs-7 text-gray-600">
            <span className="text-hover-primary text-gray-600">{shopLabel.name}</span>
          </td>
          <td className="fw-bolder fs-5 text-end">¥{data.profit.toLocaleString()}</td>
          <td className="fw-bolder fs-5 text-end">¥{data.excluded_sales.toLocaleString()}</td>
          <td className="fw-bolder fs-5 text-end">¥{data.total_up_daily_budgets.toLocaleString()}</td>
          <td
            className={`fw-bolder fs-5 text-end ${ progressRateSales < 100 ? 'text-danger' : 'text-primary'}`}                           >
            {progressRateSales}%
          </td>
          <td
            className={`fw-bolder fs-5 text-end ${colorClassFoodCostRate}`}
          >
            {data.food_cost_rate}%
          </td>
          <td
            className={`fw-bolder fs-5 text-end ${colorClassLaborCostRate}`}
          >
            {data.labor_cost_rate}%
          </td>
        </tr>
      );
    });

    return summaries;
  };

  return (
    <div className="card-body pt-1">
      <div className="table-responsive">
        <table id="datatable_fixed_header2" className="table table-rounded table-striped border border-gray-200 gs-5 gy-5 nowrap order-column">
          <thead>
            <tr className="fw-bold fs-8 text-gray-600 px-7 border-bottom-0">
              <th className="nowrap text-gray-500"></th>
              <th className="align-middle min-w-150px text-gray-500">店舗ラベル</th>
              <th className="nowrap text-gray-500 text-end">利益</th>
              <th className="nowrap text-gray-500 text-end">売上 (税抜)</th>
              <th className="nowrap text-gray-500 text-end">売上目標</th>
              <th className="nowrap text-gray-500 text-end">売上達成率
                <span className="ms-2" data-bs-toggle="tooltip" data-bs-placement="right" title="前日までの売上累計/前日までの売上予算累計">
                  <span className="svg-icon svg-icon-gray-500 svg-icon-3"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                    <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                    <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/>
                    </svg>
                  </span>
                </span>
              </th>
              <th className="nowrap text-gray-500 text-end">食材仕入率
              </th>
              <th className="nowrap text-gray-500 text-end">PA人件費率
              </th>
            </tr>
          </thead>
          <tbody>
            {renderSummaries()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default List;
