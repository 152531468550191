import React, { useEffect, useState } from 'react';
import ModalLabels from './modal_labels';
import Chart from './chart';
import axios from '../../../lib/axios';
import moment from 'moment';

const AverageUnitCost = ({ labels, checkedLabelIds }) => {
  const [term, setTerm] = useState(6);
  const [labelIds, setLabelIds] = useState(checkedLabelIds ?? []);
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]); // 平均単価推移
  const [currentFrom, setCurrentFrom] = useState(null);
  const [currentTo] = useState(moment().format('YYYY-MM-DD'));

  const getAverageUnitCost = (from, to) => {
    axios.get(`/cost_reports/average_unit_cost_data`, {params: { label_id: labelIds, from: from, to: to }})
      .then((response) => {
        setData(response.data);
      });
  };

  // Chart用データ作成
  useEffect(() => {
    const newCategories = [];
    const months = [...Array(term)].map((_, count) => {
      const currentMonth = moment().startOf('month');
      const ago = term - (count + 1);
      const month = currentMonth.subtract(ago, 'month');
      newCategories.push(month.format('YYYY年MM月')); // Chart表示用
      return month.format('YYYY-MM-DD');
    });

    const newSeries = data.map((label_data) => {
      const data = months.map((month) => {
        if (label_data.data[month]) {
          return label_data.data[month].unit_cost;
        } else {
          return 0;
        }
      });

      return {
        name: label_data.name,
        data: data
      };
    });
    setSeries(newSeries);
    setCategories(newCategories);
  }, [data]);

  useEffect(() => {
    if (currentFrom && currentTo) {
        getAverageUnitCost(currentFrom, currentTo);
    }
  }, [labelIds, currentFrom, currentTo]);

  useEffect(() => {
    setCurrentFrom(moment().startOf('month').subtract(term-1, 'month').format('YYYY-MM-DD'));
  }, [term]);

  useEffect(() => {
    // ラベル選択用
    $('#display-term').select2({
      minimumResultsForSearch: Infinity
    });
    // 期間選択
    $('#display-term').on('select2:select', (e) => {
      const value = parseInt(e.currentTarget.value);
      setTerm(value);
    });
  }, []);

  const changeLabelsHandler = (newLabelIds) => {
    setLabelIds(newLabelIds);
  };

  const renderHeader = () => {
    return (
      <div className="mt-6 mb-4" id="kt_toolbar">
        <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack align-middle">
          <div className="page-title d-flex align-items-center flex-wrap col-xs-12 col-xxl-6">
            <h1 className="d-flex text-dark fw-boldester fs-4 align-items-center">平均単価推移</h1>
          </div>
          <div className="header-action-area justify-content-end col-xxl-6 col-xs-12">
            <div className="header-action justify-content-end">
              <div className="text-gray-600 fs-8 me-3 nowrap">表示データ</div>
              <select
                id="display-term"
                className="form-select py-2 w-auto"
                data-control="select2"
                data-hide-search="true"
              >
                <option value="6">直近6ヶ月</option>
                <option value="12">直近12ヶ月</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSummary = () => {
    return data.map((label_data, index) => {
      return (
        <tr className="align-middle" key={`summary-${index}`}>
          <td className="fw-bold fs-7 text-gray-600"><a className="text-gray-600">{label_data.name}</a></td>
          <td className="fw-bolder fs-5 text-end">¥{label_data.total.cost.toLocaleString()}</td>
          <td className="fw-bolder fs-5 text-end">{label_data.total.num.toLocaleString()}</td>
          <td className="fw-bolder fs-5 text-end">¥{label_data.total.unit_cost.toLocaleString()}</td>
        </tr>
      )
    });
  };

  return (
    <>
      {renderHeader()}
      <div className="app-container container-xxl">
        <div className="row g-5 g-xl-5 mb-5 mb-xl-8">
          <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12">
            <div className="card">
              <div className="card-header border-0 pt-8 pb-4 gap-6">
                <div className="d-flex flex-stack">
                  <div className="symbol symbol-30px me-4">
                    <span className="symbol-label bg-light-primary">
                      <span className="svg-icon svg-icon-2 svg-icon-primary">
                        <span className="section-icon">
                          <i className="ki-duotone ki-basket-ok">
                            <i className="path1"></i>
                            <i className="path2"></i>
                            <i className="path3"></i>
                            <i className="path4"></i>
                          </i>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
                    <div className="me-5">
                      <span className="text-gray-800 fw-bolder fs-5">仕入商品ラベル別サマリー </span>
                    </div>
                  </div>
                </div>
                <div className="card-title my-0 mobile-flex-column gap-4 mobile-w-100 me-0">
                  <div className="w-auto mobile-w-100">
                    <div className="w-auto">
                      <button type="button" className="btn btn-sm fs-7 fw-bold btn-primary nowrap mobile-w-100" data-bs-toggle="modal" data-bs-target="#modal_labels">
                        <i className="ki-outline ki-save-2 fs-4 ms-n1 me-0 mb-1">
                        </i>
                        仕入商品ラベル選択
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body pt-1">
                <div className="table-responsive">
                  <div className="showmore">
                    <table id="datatable_fixed_header1" className="table table-rounded table-striped border border-gray-200 gs-5 gy-5 nowrap order-column">
                      <thead>
                        <tr className="fw-bold fs-8 text-gray-600 px-7 border-bottom-0">
                          <th className="align-middle min-w-150px text-gray-500">仕入商品ラベル</th>
                          <th className="nowrap text-gray-500 text-end">仕入金額（税抜）</th>
                          <th className="nowrap text-gray-500 text-end">数量</th>
                          <th className="nowrap text-gray-500 text-end">平均単価</th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderSummary()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12">
            <div className="card">
              <div
                className="card-body d-flex justify-content-between flex-column pb-0 px-0 pt-8"
              >
                <div className="px-8">
                  <div className="pb-0">
                    <div className="d-flex flex-stack pb-0">
                      <div className="symbol symbol-30px me-4">
                        <span className="symbol-label bg-light-primary">
                          <span
                            className="svg-icon svg-icon-2 svg-icon-primary"
                          >
                            <span className="section-icon">
                              <i className="ki-duotone ki-financial-schedule">
                                <i className="path1"></i>
                                <i className="path2"></i>
                                <i className="path3"></i>
                                <i className="path4"></i>
                              </i>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-5">
                            平均単価推移
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <Chart series={series} categories={categories} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalLabels labels={labels} checkedLabelIds={labelIds} changeLabelsHandler={changeLabelsHandler} />
    </>
  );
};

export default AverageUnitCost;
