import React, { useEffect, useState, useMemo, useCallback } from 'react';
import EditablePrice from './cards/editable_price';
import EditableVariable from './cards/editable_variable';
import Margin from './cards/margin';
import Qty from './cards/qty';
import PriceQty from './cards/price_qty';
import VariableQty from './cards/variable_qty';
import MarginQty from './cards/margin_qty';
import FixedCost from './cards/fixed_cost';
import EditableGain from './cards/editable_gain';
import axios from '../../../lib/axios';
import moment from "moment";
import { Decimal } from 'decimal.js';

const EditSetting = ({ defaultMonthlyBudget, businessDays, otherCost, otherCostRate, months }) => {
  const [monthlyBudget, setMonthlyBudget] = useState(defaultMonthlyBudget);
  const [gain, setGain] = useState(defaultMonthlyBudget.profit); // 月次利益目標
  const [foodCostRate, setFoodCostRate] = useState(defaultMonthlyBudget.food_cost_rate); // 仕入比率
  const [laborCostRate, setLaborCostRate] = useState(defaultMonthlyBudget.labor_cost_rate); // PA人件費率
  const [price, setPrice] = useState(defaultMonthlyBudget.sales_per_customer); // 客単価
  const [dailyBudgets, setDailyBudgets] = useState({}); // 日次予算

  const currentMonth = useMemo(() => moment(`${defaultMonthlyBudget.year_month}01`));
  const days = useMemo(() => currentMonth.daysInMonth()); // 月の日数
  const businessDaysNum = useMemo(() => businessDays.length); // 営業日数

  useEffect(() => {
    // 月選択用
    $('#target-month').select2({
      minimumResultsForSearch: Infinity
    });
    $('#target-month').on('select2:select', (e) => {
      const month = moment(e.currentTarget.value);
      const y = month.year();
      const m = month.month() + 1;

      location.href = `/shops/monthly_budgets/${y}/${m}/setting/edit`;
    });
  }, []);

  useEffect(() => {
    calculateDailyBudgets();
  }, [gain, foodCostRate, laborCostRate, price, calculateDailyBudgets]);

  // Margin
  const marginRate = useCallback(() => {
    if (foodCostRate != null && laborCostRate != null) {
      const oneHundredDecimal = new Decimal(100);
      const foodCostRateDecimal = new Decimal(foodCostRate);
      const laborCostRateDecimal = new Decimal(laborCostRate);
      const otherCostRateDecimal = new Decimal(otherCostRate ?? 0);

      return oneHundredDecimal.minus(foodCostRateDecimal.plus(laborCostRateDecimal).plus(otherCostRateDecimal)).toNumber();
    }

    return null;
  }, [foodCostRate, laborCostRate, otherCostRate]);

  // Margin x Qty
  const marginQty = useCallback(() => {
    if (gain != null) {
      return (otherCost ?? 0) + gain;
    }

    return null;
  }, [otherCost, gain]);

  // Price x Qty
  const priceQty = useCallback(() => {
    if (marginRate() != null && marginQty() != null) {
      if (marginRate() == 0) {
        return 0;
      }
      return Math.round(marginQty() / (marginRate() / 100));
    }

    return null;
  }, [marginRate, marginQty]);

  //  Variable x Qty
  const variableQty = useCallback(() => {
    if (marginRate() != null && priceQty() != null) {
      return priceQty() - marginQty();
    }

    return null;
  }, [priceQty, marginQty]);

  // Qty
  const qty = useCallback(() => {
    if (priceQty() != null && price != null) {
      return Math.round(priceQty() / price);
    }

    return null;
  }, [priceQty, price]);

  // 日次を算出
  const calculateDailyBudgets = () => {
    if (qty() == null || priceQty() == null || foodCostRate == null || laborCostRate == null) {
      // 入力が揃っていない時は日時は作成しない
      setDailyBudgets({});
      return;
    }

    // 日次を計算
    const dailyCustomers = Math.round(qty() / businessDaysNum); // 目標日次客数
    const dailySales = Math.round(priceQty() / businessDaysNum); // 目標日次売上
    // 食材原価目標
    const monthlyFoodCost = priceQty() * (foodCostRate / 100);
    const dailyFoodCost = Math.round(monthlyFoodCost / businessDaysNum);
    // PA人件費目標
    const monthlyLaborCost = priceQty() * (laborCostRate / 100);
    const dailyLaborCost = Math.round(monthlyLaborCost / businessDaysNum);

    // 日次予算に設定
    let newDailyBudgets = {};
    const year = currentMonth.year();
    const month = currentMonth.month();
    for (let day = 1; day <= days; day++) {
      const date = moment({ year: year, month: month, date: day });
      const key = date.format('YYYY-MM-DD');
      if (businessDays.indexOf(key) === -1) {
        continue;
      }

      newDailyBudgets[key] = {
        customers: dailyCustomers,
        sales: dailySales,
        food_cost: dailyFoodCost,
        labor_cost: dailyLaborCost,
      }
    }
    setDailyBudgets(newDailyBudgets);
  };

  const clickSaveHandler = () => {
    Swal.fire({
      text: "バイアス設定や手動設定したものはリセットされます",
      icon: "warning",
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: "保存する",
      cancelButtonText: 'キャンセル',
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: 'btn btn-light-dark'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const year = currentMonth.year();
        const month = currentMonth.month() + 1;
        const url = `/shops/monthly_budgets/${year}/${month}/setting`;
        let daily = [];
        Object.keys(dailyBudgets).forEach((key) => {
            daily.push({
              date: key,
              ...dailyBudgets[key],
            });
        });

        axios.post(url, {
          monthly_budget: {
            profit: gain,
            food_cost_rate: foodCostRate,
            labor_cost_rate: laborCostRate,
            sales_per_customer: price
          },
          daily_budget: daily
        })
        .then((_response) => {
          window.location.href = url;
        })
        .catch((_error) => {
          alert('保存に失敗しました。設定内容をご確認ください');
        });
      }
    });
  };

  const clickCancelHandler = () => {
    const year = currentMonth.year();
    const month = currentMonth.month() + 1;
    const url = `/shops/monthly_budgets/${year}/${month}/setting`;
    window.location.href = url;
  };

  return (
    <div className="card card-flush">
      <div className="card-header border-0">
        <div className="card-title mt-2 mt-lg-4">
          <h3 className="fw-bolder me-2 fs-5">月次予算目標</h3>
        </div>
        <div className="col-12 justify-content-between flex-row-fluid d-flex mt-0 mb-6 mobile-flex-column gap-6">
          <div className="d-flex align-items-center gap-2 gap-lg-3">
            <div className="text-muted fs-8 me-1 nowrap">対象月</div>
            <select
              id="target-month"
              className="form-select form-select-sm form-select-solid mobile-w-100"
              data-control="select2"
              data-hide-search="true"
              defaultValue={currentMonth.format('YYYY-MM-DD')}
            >
              {months.map((month) => {
                return (
                  <option value={month[1]} key={`month-option-${month[1]}`}>{month[0]}</option>
                )
              })}
            </select>
          </div>
          <div className="d-flex gap-2">
            <a className="btn btn-sm btn-light" onClick={clickCancelHandler}>
              キャンセル
            </a>
            <button id="save_warning" className="btn btn-sm btn-primary" onClick={clickSaveHandler}>
              反映･保存する
              <i className="ki-outline ki-arrow-right fs-3 me-n1 ms-1 mb-1"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="card-body pt-0">
        <div className="row justify-content-evenly text-center fw-bold fs-7 text-gray-600 mobile-hide tablet-hide mt-2">
          <div className="col-3">客単価</div>
          <div className="col-3">×　　　客数　　　=</div>
          <div className="col-6">売上（税抜）</div>
        </div>
        <div className="row g-4 justify-content-evenly mt-1 mx-n1">
          <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-6 row g-2 mt-0 justify-content-evenly mb-4 ms-0">
            <div className="col-4 px-0 gy-0 mt-2">
              <EditablePrice price={price} changeHandler={setPrice} />
            </div>
            <div className="col-4 px-0">
              <EditableVariable
                otherCostRate={otherCostRate ?? 0}
                foodCostRate={foodCostRate}
                laborCostRate={laborCostRate}
                price={price}
                changeFoodCostRateHandler={setFoodCostRate}
                changeLaborCostRateHandler={setLaborCostRate}
              />
              <Margin
                active={true}
                marginRate={marginRate()}
                price={price}
                style={{ height: '247px' }}
              />
            </div>
            <div className="col-4 ps-2">
              <Qty active={true} qty={qty()} />
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-6 row g-2 mt-0 justify-content-evenly mb-4 me-1">
            <div className="col-4 justify-content-evenly px-0 gy-0 mt-2">
              <PriceQty active={true} priceQty={priceQty()} />
            </div>
            <div className="col-8 justify-content-evenly px-0">
              <div className="row gx-2">
                <div className="col-12 gx-0 mt-0">
                  <VariableQty active={true} variableQty={variableQty()} heightClass="h-350px" />
                </div>
              </div>
              <div className="row gx-2 justify-content-evenly">
                <div className="col-6 gx-3 mt-0 gy-0 px-0">
                  <MarginQty active={true} marginQty={marginQty()} />
                </div>
                <div className="col-6 gx-3 flex-column px-0">
                  <FixedCost active={true} fixedCost={otherCost ?? 0} />
                  <EditableGain gain={gain} changeHandler={setGain} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSetting;
