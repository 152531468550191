import React from 'react';

const DailyCalendarTotalFooter = ({budgetGoal, diffTotalSales}) => {
  return (
    <div className="mt-5 d-flex justify-content-between">
      <div className="d-flex gap-1 gap-md-4 align-items-center mobile-flex-column">
        <div className="fw-bold fs-8 text-gray-500 mt-1">月次予算目標売上</div>
        <div className="fw-bolder fs-3 text-gray-500">¥{budgetGoal.monthly_sales.toLocaleString()}</div>
      </div>
      <div className="d-flex gap-1 gap-md-4 align-items-end mobile-flex-column text-end">
        <div className="fw-bold fs-8 text-gray-500 mb-1">予算合計との差分</div>
        <div className="fw-bolder fs-3">{diffTotalSales > 0 ? '-' : ''}¥{Math.abs(diffTotalSales).toLocaleString()}</div>
      </div>
    </div>
  );
};

export default DailyCalendarTotalFooter;
